import {Vue} from "vue-property-decorator";

export class RegExUtils extends Vue {

  static PHONE_US_DOES_NOT_START_WITH_1_OR_0 = /^\(([2-9]{1}[0-9]{2})\) (([0-9]){3})-[0-9]{4}$/

  //  EMAIL
  static EMAIL = /^([a-zA-Z0-9]([!#$%&'*=?^`{|._\-+1]{0,1}[a-zA-Z0-9]){0,62})@([a-zA-Z0-9]([-]{0,1}[a-zA-Z0-9]){0,62}\.)+([a-zA-Z]{2,63})$/

  static ALPHA_NUM_WITH_SPACE = /^[a-zA-Z0-9\s]+([a-zA-Z0-9\s]+)*$/

  static ALPHA_WITH_SPACE_CHARS = /^[A-Za-z\s\-,.#&]+$/

  static USA_POSTAL_CODE = /^\d{5}(-\d{4})?$/;

  static ALPHA_NUM_WITH_SPACE_CHARS = /^[a-zA-Z0-9\s\-,.#&]+$/

  static ADDRESS = /^[a-zA-Z0-9\-\s,.#&()!'\\/]*[a-zA-Z]+[a-zA-Z0-9\-\s,.#&()!'\\/]*$/

  static CITY_COUNTY = /^[A-Za-z\s.\-’‘']+$/

  static TEXT_AREA = /^[a-zA-Z0-9À-ÿ ?$@#\\/()"'!,+\-=_:.&€£*%’\s]+$/
}

